import React from "react";
import styled from "styled-components";
import HashBanksLogo from "../assets/hashBanksLogo.png";
import Header from "../containers/Header";
import LinkButton from "../components/LinkButton";
import MockDataLogo from "../assets/mock-data-logo.png";
import Profile from "../assets/profile9.jpg";
import ffpLogo from '../assets/ffp-logo-cms-site.svg';

const ProjectsContainer = styled.div`
  background-color: #1e1e2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  color: #f0f0f0;

  h1 {
    font-size: 2.5rem;
    color: #f0f0f0;
    margin-bottom: 40px;
  }
`;

const ProjectCard = styled.div`
  background: linear-gradient(145deg, #2d2d3e, #1a1a28);
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  padding: 30px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 700px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem;
    color: #cfcfcf;
    margin-bottom: 20px;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 12px;
  margin-bottom: 20px;
  object-fit: cover;
`;

const ProjectDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const ProjectLink = styled.a`
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;

  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #4a90e2;
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #357ab7;
    }
  }
`;

export default () => (
  <ProjectsContainer>
    <Header primary />
    <br />
    <ProjectCard>
      <ProjectImage src={ffpLogo} alt="Fact Finders Pro Tool Logo" />
      <h2>Fact Checking Tool</h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
        A tool that allows users to fact-check using various media types including text, URLs, and videos. <br/> <i>Disclaimer - This Demo is for testing purpuses, it does not utilize the correct and updated data models, and the inputs are limited to Text and URLs.</i>
      </ProjectDescription>
      <ProjectLink href="https://ffp-tool.vercel.app/" target="_blank">
        <button>View Beta Demo</button>
      </ProjectLink>
    </ProjectCard>

    <ProjectCard>
      <ProjectImage src={HashBanksLogo} alt="#Banq Logo" />
      <h2>#Banq</h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
        A Jam-Stack app where users can effortlessly create hashtags and organize them in hashtag banks.
      </ProjectDescription>
      <ProjectLink href="https://hashbanks.vercel.app/#/" target="_blank">
        <button>View Project</button>
      </ProjectLink>
    </ProjectCard>
    <ProjectCard>
      <ProjectImage src={MockDataLogo} alt="Friendly Data Generator Logo" />
      <h2>Friendly Data Generator</h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
        This app helps developers quickly generate JSON data for their mock-data needs with a user-friendly interface, boosting efficiency.
      </ProjectDescription>
      <ProjectLink href="https://friendly-data-generator.vercel.app/" target="_blank">
        <button>View Project</button>
      </ProjectLink>
    </ProjectCard>



    <ProjectCard>
      <ProjectImage
        src={Profile}
        alt="Personal Profile"
        style={{ borderRadius: "50%", width: "150px", height: "150px" }}
      />
      <h2>PJDUX</h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
        My personal website built with React.js, Node.js, and a MySQL database. Learn more about me and get in touch!
      </ProjectDescription>
      <ProjectLink href="/">
        <button>View Project</button>
      </ProjectLink>
    </ProjectCard>

  
  </ProjectsContainer>
);
