import React, { useRef } from "react";
import ResumePNG from "../assets/resumePDsite.png";
import { ResumeWrapper } from "../styled-containers/ResumeWrapper";
import Header from "../containers/Header";
import LinkButton from "../components/LinkButton";
import jsPDF from "jspdf";

// problems

export function Resume(props) {
  const fileName = `Pedro Diaz Resume`;
  const canvasRef = useRef(null);

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const img = new Image();
    img.src = ResumePNG;
    img.onload = function() {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 211, 298);
      pdf.save(`${fileName}.pdf`);
    };
  };

  return (
    <ResumeWrapper className="resume">
      <Header primary />
      <canvas ref={canvasRef} style={{ display: "none" }} />

      <img className="resume-img" src={ResumePNG} alt="resume-png" />

      <LinkButton onClick={handleDownload}>Download</LinkButton>
    </ResumeWrapper>
  );
}

// rremember this to use in other projects when setting up a png for download as pdf
